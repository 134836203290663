import {
  DELETE_ITEMTYPE_SUCCESS,
  FETCH_AGENTS_SUCCESS,
  FETCH_CHANELLS_SUCCESS,
  FETCH_CUSTOMERSBYID_SUCCESS,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_ITEMS_SUCCESS,
  FETCH_COMMISSIONMAIN_SUCCESS,
  FETCH_QUANTITY_AV_SUCCESS,
  FETCH_SITES_SUCCESS,
  FETCH_STORE_SUCCESS,
  FETCH_UPDATED_MANAGE_SUCCESS,
  REGISTER_ITEM_TYPE_SUCCESS,
  REGISTER_ITEM__SUCCESS,
  REGISTER_PAYMENT__SUCCESS, REGISTER_BANK_SUCCESS,
  REGISTER_BANK_ERROR,
  REGISTER_PURCHASES__SUCCESS,
  REGISTER_STOCKS__SUCCESS,
  REGISTER_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_GROUPS_SUCCESS,
  FETCH_DEDUCTION_SUCCESS,
  FETCH_GETALLTITLE_AV_SUCCESS,
  FETCH_ITEMBYID_SUCCESS,
  FETCH_DEPARTMENT_AV_SUCCESS,
  GET_EMPLOYEE__SUCCESS,
  GET_ADVANCE__SUCCESS,
  FETCH_CARDS_SUCCESS,
  FETCH_GROUPSTOASSIGN_SUCCESS,
  REGISTER_DEDUCTION__SUCCESS,
  FETCH_ZONES_SUCCESS,
  FETCH_APIHORMUUDIPTV_SUCCESS,
  FETCH_ALL_ZONES_SUCCESS,
  FETCH_GROUP_ZONES_SUCCESS,
  FETCH_SINGLE_CUSTOMER,
  FETCH__RECEIVERS,
  REGISTER_LOGIN,
  LOGUT_USER,
  FETCH_SINGLE_PURCHASE_ID,
  FETCH_SINGLE_SITE_CARDS,
  FETCH_SINGLE_CARD_NUMBER,
  FETCH_IPTV_BY_ID,
  Fetch_associated_receivers,
  FETCH_PURCHASED_ASSOCIATED_RECEIVERS_BY_ID,
  REGISTER_PAYMENT__AMOUNT,
  GET_BYID_CUSTOMERS_SUCCESS,
  GET_BYID_RECIVE_SUCCESS,
  ITEM_NOT_RECEIVER,
  FETCH_RECEIVER_ITEM,
  FETCH_RECEIVER_CARD_NUMBER_AND_SERIALS,
  FETCH_GETZONES_SUCCESS,
  FETCH_ZONESTOASSIGN_SUCCESS,
  FETCH_EMPLOYEESTOASSIGN_SUCCESS,
  GET_BYID_GROUP_ALL_SUCCESS,
  FETCH_GETBYIDZONES_SUCCESS,
  FETCH_OPEN_CARDS_SUCCESS,
  FETCH_CABLES_SUCCESS,
  FETCH__ITEMRECEIVERS,
  GET_BYID_GROUP_SUCCESS,
  // FETCH_GETBYIDZONES_SUCCESS,
  FETCH_GETBYIDEMPLOYEE_SUCCESS,
  GET_TICKETS__SUCCESS,
  FETCH_PURCHASE_ORDERS,
  FETCH_PURCHASE_ORDERS_BY_ID,
  REGISTER_PURCHASEORDERS__SUCCESS,
  GET_BYID_GROUP_CABLE_ALL_SUCCESS,
  FETCH_GETBYIDZONESCABLE_SUCCESS,
  FETCH_ALL_PURCHASES,
  FECH_PURCHASE_RECEIVE_BY_ID,
  FECH_PURCHASE_ORDERS_RECEIVERS,
  FETCH_PURCHASE_ORDERS_BY_ID_RECEIVER,
  FETCH_PENDING_TICKETS,
  GET_BYID_CARD_NUMBER,
  FETCH_ALL_IPTV_SUCCESS,
  FETCH_INVENTORY_REPORTS,
  FETCH_PURCHASE_REPORTS,
  FETCH_ASSIGNGROUPS_SUCCESS,
  FETCH_SALES_REPORTS,
  FETCH_BANK_SUCCESS,
  PURCHASE_REPORT_DATE,
  PURCHASE_SALES_DATE,
  PAYMENT_BY_ID,
  PAYMENT_FILTER_DATE,
  UPLOADED_ERRORS,
  MANAGE_PURCHASE_FILTER,
  FETCH_CABLE_BIGIN,
  FETCH_LAST_SERIAL,
  FETCH_GROUPS_BIGIN,
  FETCH_COMMISSION_SUCCESS,
  FETCH_CABLE_INFO,
  ITEM_NOT_RECEIVER_by_id,
  FETCH_LAST_SERIAL_sp,
  FETCH_LAST_SERIAL_sp_SINGLE,
  FETCH_RECEIVER,
  // FETCH_GROUPS_BIGIN,
  TRANSFER_ITEM,
  RESET_ITEM_TRANSFER,
  RESET_RECEIVER_TRANSFER,
  RESET_BILLING_RECEIVER,
  RESET_BILLING_ITEM,
  RESET_ITEM_SALES_ITEM,
  RESET_ITEM_SALES_RECEIVER,
  RESET_QUANTITY_AVAILABLE,
  GET_ALL_USER,
  FETCH_PENDINGPAYROLL_SUCCESS,
  FETCH_PAYROLLYEARMONTH_SUCCESS,
  FETCH_MESSAGE_SUCCESS,
  FETCH_SINGLE_EMPLOYEE,
  GET_CUSTOMER_CARD_LIST,
  GET_CLOSED_CARDS,
  GET_MENUS,
  LOADING2,
  RECEIVER_DISTURBUTION_BEGIN,
  RECEIVER_DISTURBUTION_SUCCESS,
  ITEM_DISTURBUTION_SUCCESS,
  ITEM_DISTURBUTION_BEGIN,
  ITEM_REG_BEGIN,
  SUPPLIER_REG_BEGIN,
  CUSTOMER_REG_BEGIN,
  SITE_REG_BEGIN,
  STORE_REG_BEGIN,
  SALESAGENT_REG_BEGIN,
  PACKAGE_REG_BEGIN,
  GET_SUPERVISORS,
  GET_SUPERVISORS_SPECIAL,
  RESET_THE_CARDNUMBER,
  GET_SINGLE_MENU,
  GET_SIDEBAR_MENU,
  AUTORECEPTS,
  GET_DISTRICTS,
  FETCH_HOR_IPT,
  FETCH_OPEN_CARD_REPORT,
  FETCH_ALL_OPEN_CARD_REPORT,
  FETCH_CABLE_REPORT,
  OPEN_CARD_REG_BEGIN,
  OPEN_CARD_REG_END,
  FETCH_CLOSE_CARDS_REPORT,
  FETCH_PURCHASE_RECEIVE_REPORT,
  FETCH_ADVANCE_REPORT,
  FETCH_PURCHASE_ORDER_REPORT,
  FETCH_CUSTOMER_LIST_REPORT,
  FETCH_CUSTOMER_STATEMENT_REPORT,
  FETCH_TICKET_REG_REPORT,
  FETCH_TICKET_SUPERVISOR_REPORT,
  FETCH_PAYROLL_SUMMARY_REPORT,
  FETCH_ALL_PURCHASES_BEGIN,
  MANAGE_PURCHASE_FILTER_BEGIN,
  GET_OTP_NUM,
  OTTP_BEGIN,
  DEDUCTION_REPORT,
  SUPPLER_REPORT,

  GET_ALL_YEARS, 
  FETCH_PAYROLL_DETAILS_REPORT,

  FETCH_EMPLOYEE_REPORT,

  FETCH_TICKET_TRANSFER_REPORT,

  FETCH_TICKETS_SUMMARY_REPORT,
  FETCH_TICKET_SUPERVISOR_SUMMARY_REPORT,
  CUSTOMER_LIST,
  EXPIRE_CARDS,
  MANUAL_OPEN_CARD,
  TODAYS_TICKETS,
  TICKETS_SUPERVISOR_ADD_BEGIN,
  TICKETS_SUPERVISOR_ADD_SUCESS,
  TOP_TEN_USERS,
  REG_SHITS,
  IPTVS_ONLY,
  NASIYE_PAYMENTS,
  HUBI_LINE,
  HUBI_LINE_BEGIN,
  OPEN_IPTV_BEGIN,
  OPEN_IPTV,
  REGISTER_IPTV_BEGIN,
  REGISTER_IPTV_SUCCESS,
  IPTV_CUSTOMER_RECHARGE,
  FETCH_CUSTOMERIPTV_REPORT,
  OTT_CUSTOMER_RECHARGE,
  EXPIRED_IPTVS_REPORT,
  EXPIRED_OTT_REPORT,
  GET_MAC_ADDRESS_AND_SERIAL_NUMBER,
  FETCH_ONLY_CUSTOMERS_WITH_IPTVS,
  TRANSFER_IPTV_BEGIN,
  TRASFER_IPTV_SUCCESS,
  SOLVED_REPORT,
  SOLVED_CUSTOMERS_REPORT,
  UPDATED_CUSTOMERS,
  UPLOAD_IPTV_BEGIN,
  UPLOAD_IPTV_CUSTOMERS,
  REGISTER_NEW_CABLE_BEGIN,
  REGISTER_NEW_CABLE_SUCCESS,
  REGISTER_NEW_CABLE_ERROR,
  REGISTER_NEW_ITEM_SALES_BEGIN,
  REGISTER_NEW_ITEM_SALES_SUCCESS,
  REGISTER_NEW_ITEM_SALES_ERROR,
  IPTV_CUSTOMER,
  REGISTER_IPTV_ERROR,
  THE_CUSTOMERS_SUCCESS,
  THE_CUSTOMERS_BEGIN,
  THE_CUSTOMERS_ERROR,
  REGISTER_IPT_ORGANIZATION__SUCCESS,
  REGISTER_IPT_ORGANIZATION__ERROR,
  REGISTER_IPT_ORGANIZATION_BEGIN,
  CUSTOMER_424_REPORT,
  CORESPONDING_EMP,
  SEND_SMS_BEGIN,
  SEND_SMS_SUCCESS,
  SEND_SMS_FAIL,
  FETCH_CABLES_BEGIN_BY_DATE,
  FETCH_CABLES_SUCCESS_BY_DATE,
  FETCH_CABLES_ERROR_BY_DATE,
  AUTO_CHARGE_BEGIN,
  AUTO_CHARGE_SUCCESS,
  AUTO_CHARGE_ERROR,
  GET_ALL_ANALOG_EXPIRED_BEGIN,
  GET_ALL_ANALOG_EXPIRED_SUCCESS,
  GET_ALL_ANALOG_THREE_EXPIRED_BEGIN,
  GET_ALL_ANALOG_REMIAINGG_EXPIRED_SUCCESS,
  GET_ALL_IPTV_THREE_EXPIRED_BEGIN,
  GET_ALL_IPTV_REMIAINGG_EXPIRED_SUCCESS,
  GET_ALL_IPTV_THEE_EXPIRED_BEGIN,
  GET_ALL_IPTV_THEEE_EXPIRED_SUCCESS,
  GET_USER_REPORT_ROLE,
  SEND_FINGERPRINT_BEGIN,
  SEND_FINGERPRINT_SUCCESS,
  SEND_FINGERPRINT_FAIL,
  DEVICES_REG_BEGIN,
  FETCH_DEVICES_SUCCESS,
  FETCH_ATTENDANCE_DATA,
  FETCH_ATTENDANCE_UPDATES,
  FETCH_AVAILABLE_CARDS,
  SEND_SMS_NOTIFICATION,
  FETCH_RESOLVED_TICKETS,
  FETCH_SMS_MESSAGES,
  FETCH_SOLD_CARDS,
  CANCEL_CARD_END,
  CANCEL_CARD_BEGIN,
  GET_TICKETS_COMPLETED_SUCCESS,
  CUST_SMS_SUCCESS,
  CUST_SMS_END,
  CUST_SMS_BEGIN,
  FETCH_EXPIRED_CARDS,
  GET_ALL_IPTV_CUST
} from "./loginActions";

const reducer = (state, action) => {
  /// login
  if (action.type === "CLEAR_THE_STATE") {
    return {
      ...state,
     User:""
    };
  }
  if (action.type === "REGISTER_LOGIN_BEGIN") {
    return {
      ...state,
     isLoading:true
    };
  }
  if (action.type === "REGISTER_LOGIN") {
    return {
      ...state,
      User: action.payload.res.data,
      isLoading:false

    };
  }
  if (action.type === "REGISTER_LOGIN_Fail") {
    return {
      ...state,
     
      isLoading:false

    };
  }
  if (action.type === LOGUT_USER) {
    return {
      ...state,
      User: null,
    };
  }
  if (action.type === "REGISTER_USER_BEGINN") {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === "REGISTER_USER_BEGINN") {
    return {
      ...state,
    
      userLoading: true,
    };
  }
  if (action.type === GET_ALL_USER) {
    return {
      ...state,
      allUsers: action.payload.data,
      userLoading: false,
    };
  }
  /// supplier,
  if (action.type === SUPPLIER_REG_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === FETCH_ITEMS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      items: action.payload.items,
    };
  }

  /// item type
  if (action.type === REGISTER_ITEM_TYPE_SUCCESS) {
    return {
      ...state,
      itemTypes: action.payload.data,
    };
  }
    /// Nasiye report
    if (action.type === NASIYE_PAYMENTS) {
      return {
        ...state,
        nasiyeReport: action.payload.data,
      };
    }
  //Employees
  // if (action.type === REGISTER_EMPLOYEE_SUCCESS) {
  //   return {
  //     ...state,
  //     Employees: action.payload.data,
  //   };
  // }

  if (action.type === TODAYS_TICKETS) {
    return {
      ...state,
      todaysTikcets: action.payload.data,
    };
  }
  if (action.type === FETCH_PENDING_TICKETS) {
    return {
      ...state,
      GetPendingTickets: action.payload.data,
    };
  }

  //Groups
  if (action.type === FETCH_GROUPS_SUCCESS) {
    return {
      ...state,
      getGroups: action.payload.data,
    };
  }

  if (action.type === GET_BYID_GROUP_SUCCESS) {
    return {
      ...state,
      getByIdGroups: action.payload.data,
    };
  }

  //Zones
  if (action.type === FETCH_GETZONES_SUCCESS) {
    return {
      ...state,
      getZonesById: action.payload.data,
    };
  }

  //Group Zones
  if (action.type === FETCH_GROUP_ZONES_SUCCESS) {
    return {
      ...state,
      getGroupZones: action.payload.data,
    };
  }

  if (action.type === FETCH_GETBYIDZONES_SUCCESS) {
    return {
      ...state,
      getZoneById: action.payload.data,
    };
  }

  // //deduction
  if (action.type === REG_SHITS) {
    return {
      ...state,
      shifts: action.payload.data,
    };
  }
  //deduction
  if (action.type === FETCH_DEDUCTION_SUCCESS) {
    return {
      ...state,
      getDeduction: action.payload.data,
    };
  }
  //CUSTOMERS

  if (action.type === CUSTOMER_REG_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === FETCH_CUSTOMER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      customers: action.payload.data,
    };
  }

  if (action.type === GET_BYID_CUSTOMERS_SUCCESS) {
    return {
      ...state,
      getCustomersId: action.payload.data,
    };
  }

  ///sites

  if (action.type === SITE_REG_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === FETCH_SITES_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      sites: action.payload.data,
    };
  }

  //Zones
  if (action.type === FETCH_ZONES_SUCCESS) {
    return {
      ...state,
      Zones: action.payload.data,
    };
  }

  if (action.type === FETCH_ALL_ZONES_SUCCESS) {
    return {
      ...state,
      ZoneList: action.payload.data,
    };
  }

  ///stores
  if (action.type === FETCH_STORE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      stores: action.payload.data,
    };
  }
  if (action.type === STORE_REG_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  // PRODUCTS

  if (action.type === "DISPLAY_ALERT") {
    return {
      ...state,
      showAlert: true,
      // alertType: 'danger',
      alertText: action.payload.msg,
    };
  }
  if (action.type === "CLEAR_ALERT") {
    return {
      ...state,
      showAlert: false,
      // alertType: '',
      // alertText: '',
    };
  }

  if (action.type === ITEM_REG_BEGIN) {
    return {
      ...state,
      isLoading: true,
      isAlert: true,
    };
  }
  if (action.type === "Customers_ADD_BEGIN") {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === "customers_upload_success") {
    return {
      ...state,
      isLoading: false,
    };
  }

  ///* upload iptv cusomers
  if (action.type === UPLOAD_IPTV_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === UPLOAD_IPTV_CUSTOMERS) {
    return {
      ...state,
      isLoading: false,
      iptvCustomers: action.payload.response,
    };
  }
  if (action.type === REGISTER_ITEM__SUCCESS) {
    return {
      ...state,
      isLoading: false,
      isAlert: false,
      products: action.payload.data,
    };
  }

  //purchase Orders
  if (action.type === REGISTER_PURCHASEORDERS__SUCCESS) {
    return {
      ...state,
      purchaseOrders: action.payload.data,
    };
  }
  //purchases
  if (action.type === REGISTER_PURCHASES__SUCCESS) {
    return {
      ...state,
      purchases: action.payload.data,
    };
  }
  //payments
  if (action.type === REGISTER_PAYMENT__SUCCESS) {
    return {
      ...state,
      payments: action.payload.data,
    };
  }

  //stocks

  if (action.type === REGISTER_STOCKS__SUCCESS) {
    return {
      ...state,
      stocks: action.payload.data,
    };
  }

  // fetch single updated manage
  if (action.type === FETCH_UPDATED_MANAGE_SUCCESS) {
    return {
      ...state,
      manageSingle_id: action.payload.data,
    };
  }

  //fetch chanell packages
  //channelPackage
  if (action.type === PACKAGE_REG_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === FETCH_CHANELLS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      channelPackage: action.payload.data,
    };
  }

  /// agents

  if (action.type === TICKETS_SUPERVISOR_ADD_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === TICKETS_SUPERVISOR_ADD_SUCESS) {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (action.type === SALESAGENT_REG_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === FETCH_AGENTS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      agents: action.payload.data,
    };
  }

  /// quantity available
  if (action.type === FETCH_QUANTITY_AV_SUCCESS) {
    return {
      ...state,
      quantityAvailable: action.payload.data,
    };
  }

  // top last login 20 users
  if (action.type === TOP_TEN_USERS) {
    return {
      ...state,
      topTenUsers: action.payload.data,
    };
  }
  if (action.type === FETCH_ITEMBYID_SUCCESS) {
    return {
      ...state,
      ItemsName: action.payload.data,
    };
  }

  /// Employee
  if (action.type === FETCH_GETALLTITLE_AV_SUCCESS) {
    return {
      ...state,
      getAllTitles: action.payload.data,
    };
  }
  /// Corresponding employee
  if (action.type === CORESPONDING_EMP) {
    return {
      ...state,
      corresponEmp: action.payload.data,
    };
  }

  if (action.type === FETCH_DEPARTMENT_AV_SUCCESS) {
    return {
      ...state,
      departments: action.payload.data,
    };
  }

  if (action.type === GET_EMPLOYEE__SUCCESS) {
    return {
      ...state,
      getEmployee: action.payload.data,
    };
  }

  if (action.type === FETCH_GETBYIDEMPLOYEE_SUCCESS) {
    return {
      ...state,
      GetByIdEmployee: action.payload.data,
    };
  }

  // TICKETS

  if (action.type === GET_TICKETS__SUCCESS) {
    return {
      ...state,
      GetTickets: action.payload.data,
    };
  }

  // ADVANCE

  if (action.type === GET_ADVANCE__SUCCESS) {
    return {
      ...state,
      getAdvance: action.payload.data,
    };
  }

  if (action.type === FETCH_CARDS_SUCCESS) {
    return {
      ...state,
      cards: action.payload.data,
    };
  }

  if (action.type === FETCH_GROUPSTOASSIGN_SUCCESS) {
    return {
      ...state,
      GroupsForSite: action.payload.data,
    };
  }

  if (action.type === FETCH_ZONESTOASSIGN_SUCCESS) {
    return {
      ...state,
      ZonesForGroup: action.payload.data,
    };
  }

  if (action.type === FETCH_EMPLOYEESTOASSIGN_SUCCESS) {
    return {
      ...state,
      EmployeesFromZone: action.payload.fetchEmp,
    };
  }

  // fetch single customer
  if (action.type === FETCH_SINGLE_CUSTOMER) {
    return {
      ...state,
      singleCustomer: action.payload.data,
    };
  }

  // fetch single Employee
  if (action.type === FETCH_SINGLE_EMPLOYEE) {
    return {
      ...state,
      singleEmployee: action.payload.data,
    };
  }

  if (action.type === FETCH_MESSAGE_SUCCESS) {
    return {
      ...state,
      MessageData: action.payload.data,
    };
  }

  if (action.type === FETCH_PAYROLLYEARMONTH_SUCCESS) {
    return {
      ...state,
      PayrollYearMonth: action.payload.res,
    };
  }

  if (action.type === FETCH_PENDINGPAYROLL_SUCCESS) {
    return {
      ...state,
      PendingPayroll: action.payload.res,
    };
  }

  // fetch single Site cards
  if (action.type === FETCH_SINGLE_SITE_CARDS) {
    return {
      ...state,
      singleSiteCard: action.payload.data,
    };
  }

  //fetch recievers
  if (action.type === FETCH__RECEIVERS) {
    return {
      ...state,
      recievers: action.payload.data,
    };
  }

  if (action.type === FETCH__ITEMRECEIVERS) {
    return {
      ...state,
      itemGetAllReceiver: action.payload.data,
    };
  }

  // fetch purchased id  - not used yet
  if (action.type === FETCH_SINGLE_PURCHASE_ID) {
    return {
      ...state,
      singlePurchaseId: action.payload.data,
    };
  }
  // fetch received number card - not used yet
  if (action.type === FETCH_SINGLE_CARD_NUMBER) {
    return {
      ...state,
      singleCardNumber: action.payload.data,
    };
  }
  //get_purchases_with_associated_receivers
  if (action.type === Fetch_associated_receivers) {
    return {
      ...state,
      purchases_with_associated_receivers: action.payload.data,
    };
  }
  //get_purchases_with_associated_receivers by ids
  if (action.type === FETCH_PURCHASED_ASSOCIATED_RECEIVERS_BY_ID) {
    return {
      ...state,
      purchases_with_associated_receiversBYids: action.payload.data,
    };
  }

  //fetch customer payment
  if (action.type === REGISTER_PAYMENT__AMOUNT) {
    return {
      ...state,
      paymentAmount: action.payload.data,
    };
  }

  if (action.type === IPTVS_ONLY) {
    return {
      ...state,
      iptvsOnly: action.payload.data,
    };
  }
  if (action.type === GET_BYID_RECIVE_SUCCESS) {
    return {
      ...state,
      reciveById: action.payload.data,
    };
  }
  if (action.type === "CLEAN_THE_RECEIVERS") {
    return {
      ...state,
      reciveById: [],
    };
  }
  if (action.type === "GET_ANALOG_RECIVE_SUCCESS") {
    return {
      ...state,
      AnalogReciverBySearch: action.payload.data,
    };
  }
  if (action.type === "CLEAN_THE_ANALOG_RECEIVERS") {
    return {
      ...state,
      AnalogReciverBySearch: [],
    };
  }
  // redline receiver
  if (action.type === "GET_REDLINE_RECIVER__BYID_SUCCESS") {
    return {
      ...state,
      RedlineReciverById: action.payload.data,
    };
  }
  if (action.type === "CLEAN_THE_RECEIVERS") {
    return {
      ...state,
      RedlineReciverById: [],
    };
  }

  /// FETCH ITEMS NOT RECEIVER
  if (action.type === ITEM_NOT_RECEIVER) {
    return {
      ...state,
      itemsNotReceivers: action.payload.data,
    };
  }
  /// FETCH ITEMS  RECEIVERS
  if (action.type === FETCH_RECEIVER_ITEM) {
    return {
      ...state,
      itemsReceivers: action.payload.data,
    };
  }

  ///FetchReciverCardNumberAndSerials
  if (action.type === FETCH_RECEIVER_CARD_NUMBER_AND_SERIALS) {
    return {
      ...state,
      cardAndSerials: action.payload.data,
    };
  }

  if (action.type === GET_BYID_GROUP_ALL_SUCCESS) {
    return {
      ...state,
      getByIdGroups: action.payload.data,
    };
  }

  if (action.type === FETCH_GETBYIDZONES_SUCCESS) {
    return {
      ...state,
      getByIdZones: action.payload.data,
    };
  }
// analog cards
  if (action.type === "FETCH_OPEN_CARDS_BEGIN") {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === FETCH_OPEN_CARDS_SUCCESS) {
    return {
      ...state,
      OpenCards: action.payload.data,
      isLoading: false,
    };
  }

  // redline cards
  if (action.type === "FETCH_REDLINE_OPEN_CARDS_BEGIN") {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === "FETCH_REDLINE_OPEN_CARDS_SUCCESS") {
    return {
      ...state,
      RedlineOpenCards: action.payload.data,
      isLoading: false,
    };
  }
//*REGISTER IPTV

if (action.type === REGISTER_IPTV_BEGIN) {
  return {
    ...state,
   
    isNewCableLoading: true,
  };
}

if (action.type === REGISTER_IPTV_SUCCESS) {
  return {
    ...state,
   
    isNewCableLoading: false,

  };
}
if (action.type === REGISTER_IPTV_ERROR) {
  return {
    ...state,
   
    isNewCableLoading: false,

  };
}



if (action.type === "FETCH_CUSTOMERIPTV_REPORT_BEGIN") {
  return {
    ...state,
    isLoading:true,
  };
}
if (action.type === "FETCH_CUSTOMERIPTV_REPORT_ERROR") {
  return {
    ...state,
    isLoading:false,
  };
}
if (action.type === FETCH_CUSTOMERIPTV_REPORT) {
  return {
    ...state,
    isLoading:false,
    custiptvRepot: action.payload.data,
  };
}


//** fetch solved report */
if (action.type === SOLVED_REPORT) {
  return {
    ...state,
    solvedReport: action.payload.items,
  };
}
//** fetch solved customers report */
if (action.type === SOLVED_CUSTOMERS_REPORT) {
  return {
    ...state,
    solvedCustomersReport: action.payload.items,
  };
}


  /// ** OPEN IPTVS
  if (action.type === OPEN_IPTV_BEGIN) {
    return {
      ...state,
     
      isLoading: true,
    };
  }
  if (action.type === OPEN_IPTV) {
    return {
      ...state,
     
      isLoading: false,
      openIptv: action.payload.data
    };
  }
  if (action.type === HUBI_LINE_BEGIN) {
    return {
      ...state,
     
      isChecking: true,
    };
  }
  if (action.type === HUBI_LINE) {
    return {
      ...state,
     
      isChecking: false,
    };
  }

  if (action.type === FETCH_ALL_IPTV_SUCCESS) {
    return {
      ...state,
      IPTVS: action.payload.data,
    };
  }

  if (action.type === FETCH_CABLE_BIGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === FETCH_CABLES_SUCCESS) {
    return {
      ...state,
      Cables: action.payload.data,
      isLoading: false,
    };
  }

  if (action.type === FETCH_ASSIGNGROUPS_SUCCESS) {
    return {
      ...state,
      assignGroups: action.payload.data,
      isLoadingGroups: false,
    };
  }

  if (action.type === FETCH_GROUPS_BIGIN) {
    return {
      ...state,
      isLoadingGroups: true,
    };
  }

  if (action.type === FETCH_COMMISSION_SUCCESS) {
    return {
      ...state,
      Commissions: action.payload.data,
    };
  }

  if (action.type === FETCH_COMMISSIONMAIN_SUCCESS) {
    return {
      ...state,
      PayrollMain: action.payload.res,
    };
  }

  if (action.type === FETCH_BANK_SUCCESS) {
    return {
      ...state,
      Banks: action.payload.data,
    };
  }

  /// FETCH purchased orders
  if (action.type === FETCH_PURCHASE_ORDERS) {
    return {
      ...state,
      purchaseOrders: action.payload.data,
    };
  }
  /// * 424 report
  if (action.type === CUSTOMER_424_REPORT) {
    return {
      ...state,
      customer424Report: action.payload.data,
    };
  }

  if (action.type === GET_BYID_GROUP_CABLE_ALL_SUCCESS) {
    return {
      ...state,
      getByIdGroupsCable: action.payload.data,
    };
  }

  if (action.type === FETCH_GETBYIDZONESCABLE_SUCCESS) {
    return {
      ...state,
      getByIdZonesCables: action.payload.data,
    };
  }

  /// FETCH purchased orders BY ID
  if (action.type === FETCH_PURCHASE_ORDERS_BY_ID) {
    return {
      ...state,
      singlePurchaseOrder: action.payload.data,
    };
  }
  /// FETCH ALL PURCHASES
  if (action.type === FETCH_ALL_PURCHASES_BEGIN) {
    return {
      ...state,
      isLoading:true
    };
  }
  if (action.type === FETCH_ALL_PURCHASES) {
    return {
      ...state,
      allPurchases: action.payload.data,
      isLoading:false
    };
  }
  /// FETCH ANALOG CUSTOMER CARD LIST
  if (action.type === GET_CUSTOMER_CARD_LIST) {
    return {
      ...state,
      cardList: action.payload.response,
    };
  }
  /// FETCH REDLINE CUSTOMER CARD LIST
  if (action.type === "GET_REDLINE_CUSTOMER_CARD_LIST") {
    return {
      ...state,
      RedlineCardList: action.payload.response,
    };
  }
  /// FETCH PURCHASE RECEIVE BY ID
  if (action.type === FECH_PURCHASE_RECEIVE_BY_ID) {
    return {
      ...state,
      purchaseReceiveEdit: action.payload.data,
    };
  }
  /// FETCH PURCHASE ORDERS RECEIVERS
  if (action.type === FECH_PURCHASE_ORDERS_RECEIVERS) {
    return {
      ...state,
      purchaseOrdersRec: action.payload.data,
    };
  }

  /// FETCH purchased orders BY ID RECEIVERS
  if (action.type === FETCH_PURCHASE_ORDERS_BY_ID_RECEIVER) {
    return {
      ...state,
      singlePurchaseOrderReceiver: action.payload.data,
    };
  }
  /// FETCH card number
  if (action.type === GET_BYID_CARD_NUMBER) {
    return {
      ...state,
      getCardNumbers: action.payload.data,
    };
  }

  if (action.type === FETCH_IPTV_BY_ID) {
    return {
      ...state,
      IPTVEdit: action.payload.data,
    };
  }

  if (action.type === FETCH_APIHORMUUDIPTV_SUCCESS) {
    return {
      ...state,
      IPTVHormuud: action.payload.data,
    };
  }

  // TICKET SUPERVIOSR REPORT  report
  if (action.type === FETCH_TICKET_SUPERVISOR_REPORT) {
    return {
      ...state,
      ticketSupervisorReport: action.payload.data,
    };
  }

  // supplier report
  if (action.type === MANUAL_OPEN_CARD) {
    return {
      ...state,
      manualOpenCard: action.payload.data,
    };
  }
  // supplier report
  if (action.type === SUPPLER_REPORT) {
    return {
      ...state,
      supplierReport: action.payload.data,
    };
  }
  // deduction report
  if (action.type === DEDUCTION_REPORT) {
    return {
      ...state,
      deductionReport: action.payload.data,
    };
  }
  // TICKET REGESTARTION REPORT  report
  if (action.type === FETCH_EMPLOYEE_REPORT) {
    return {
      ...state,
      employeeReport: action.payload.data,
    };
  }
  // TICKET REGESTARTION REPORT  report
  if (action.type === FETCH_TICKET_REG_REPORT) {
    return {
      ...state,
      ticketRegReport: action.payload.data,
    };
  }
  // CUSTOMER LIST  report
  if (action.type === FETCH_CUSTOMER_STATEMENT_REPORT) {
    return {
      ...state,
      customerStatement: action.payload.billIfor,
    };
  }

  // CUSTOMER LIST  report
  if (action.type === FETCH_CUSTOMER_LIST_REPORT) {
    return {
      ...state,
      customerListReport: action.payload.customersList,
    };
  }
  // purchase ORDER report
  if (action.type === FETCH_PURCHASE_ORDER_REPORT) {
    return {
      ...state,
      purchaseOrederReport: action.payload.purchaseOrderData,
    };
  }
  // purchase receive report
  if (action.type === FETCH_PURCHASE_RECEIVE_REPORT) {
    return {
      ...state,
      purchaseRecReport: action.payload.data,
    };
  }
  // fetch inventory reports
  if (action.type === FETCH_INVENTORY_REPORTS) {
    return {
      ...state,
      inventoryReports: action.payload.data,
    };
  }

  // fetch purchase reports
  if (action.type === AUTORECEPTS) {
    return {
      ...state,
      autorecepts: action.payload.data,
    };
  }
//**sending sms */
if (action.type === "SMS_BEGIN") {
  return {
    ...state,
    isSending:true,
  };
}
if (action.type === "SMS_END") {
  return {
    ...state,
    isSending:false,
  };
}
// resend sms
if (action.type === "RESEND_SMS_BEGIN") {
  return {
    ...state,
    isSending:true,
  };
}
if (action.type === "RESEND_SMS_END") {
  return {
    ...state,
    isSending:false,
  };
}

//*Current expired cars
if (action.type === "EXPIRE_CURRENT_CARDS_BEGIN") {
  return {
    ...state,
    isNewCableLoading:true,
  };
}
if (action.type === "EXPIRE_CURRENT_CARDS_END") {
  return {
    ...state,
    isNewCableLoading:false,
    currentExpiredCards:action.payload.data,
  };
}

  //CUSTOMER_LIST reports
  if (action.type === "EXPIRE_CARDS_BEGIN") {
    return {
      ...state,
      isNewCableLoading:true,
    };
  }
  if (action.type === EXPIRE_CARDS) {
    return {
      ...state,
      expireCards: action.payload.data,
      isNewCableLoading:false,
    };
  }
  //CUSTOMER_LIST reports
  if (action.type === CUSTOMER_LIST) {
    return {
      ...state,
      customerList: action.payload.data,
    };
  }
  // fetch Tickets summary reports
  if (action.type === FETCH_TICKETS_SUMMARY_REPORT) {
    return {
      ...state,
      titcketsSummary: action.payload.data,
    };
  }
  // FETCH_TICKET_SUPERVISOR_SUMMARY_REPORTreports
  if (action.type === FETCH_TICKET_SUPERVISOR_SUMMARY_REPORT) {
    return {
      ...state,
      titcketsSupervisorSummaryReport: action.payload.data,
    };
  }
  // fetch SALES reports
  if (action.type === FETCH_SALES_REPORTS) {
    return {
      ...state,
      salesReport: action.payload.data,
    };
  }
  // fetch PURCHASE reports by dates
  if (action.type === PURCHASE_REPORT_DATE) {
    return {
      ...state,
      purcReportByDate: action.payload.data,
    };
  }
  // fetch advance reports by dates
  if (action.type === FETCH_ADVANCE_REPORT) {
    return {
      ...state,
      advancesReport: action.payload.data,
    };
  }
  // fetch sales reports by dates
  if (action.type === PURCHASE_SALES_DATE) {
    return {
      ...state,
      salesRepoDate: action.payload.data,
    };
  }
  // fetch payments by ids
  if (action.type === PAYMENT_BY_ID) {
    return {
      ...state,
      paymentByIDS: action.payload.data,
    };
  }
  // fetch payments by DATE
  if (action.type === PAYMENT_FILTER_DATE) {
    return {
      ...state,
      paymentByFilter: action.payload.data,
    };
  }
  // uploaded erros
  if (action.type === UPLOADED_ERRORS) {
    return {
      ...state,
      uploadedErrors: action.payload,
      isLoading: false,
    };
  }
  if (action.type === "RECEIVER_ADD_BEGIN") {
    return {
      ...state,
      RECEIVER_ADD_SUCCESS: true,
    };
  }
  if (action.type === "RECEIVER_ADD_SUCCESS") {
    return {
      ...state,
      RECEIVER_ADD_SUCCESS: false,
    };
  }
  if (action.type === RECEIVER_DISTURBUTION_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === RECEIVER_DISTURBUTION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (action.type === ITEM_DISTURBUTION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (action.type === ITEM_DISTURBUTION_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }

  // Transfer Analog Card Number
  if (action.type === "TRANSFER_SUCCESS") {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (action.type === "TRANSFER_BEGIN") {
    return {
      ...state,
      isLoading: true,
    };
  }
  /// FETCH manag by date
  if (action.type === "OTTP_BEGIN_KOOW") {
    return {
      ...state,
      isLoading: true,

      
    };
  }
  if (action.type === "OTTP_BEGIN_ERROR") {
    return {
      ...state,
      isLoading: false,

      
    };
  }
  if (action.type === OTTP_BEGIN) {
    return {
      ...state,
      OTPNumber: action.payload.res.data?.user?.OTP,
      theUser:action.payload.res,
      otp:action.payload.res.data?.user?.OTP,
      isLoading: false,
      
    };
  }
  if (action.type === "RESET_OTP_NUM") {
    return {
      ...state,
      OTPNumber: ""
     
      
    };
  }
  // if (action.type === GET_OTP_NUM) {
  //   return {
  //     ...state,
  //     OTPNumber: action.payload.number,
      
  //   };
  // }
  /// FETCH manag by date
  if (action.type === MANAGE_PURCHASE_FILTER_BEGIN) {
    return {
      ...state,
      manageFilterByDate: action.payload,
      isLoading:true
    };
  }
  /// FETCH manag by date
  if (action.type === MANAGE_PURCHASE_FILTER) {
    return {
      ...state,
      manageFilterByDate: action.payload,
      isLoading:false
    };
  }

  if (action.type === FETCH_CUSTOMERSBYID_SUCCESS) {
    return {
      ...state,
      CustomerById: action.payload.data,
    };
  }

  /// fetch serial form to th end
  if (action.type === FETCH_LAST_SERIAL) {
    return {
      ...state,
      fetchLastSerial: action.payload.data,
    };
  }
  /// fetch CABLE INFORMATION
  if (action.type === FETCH_CABLE_INFO) {
    return {
      ...state,
      cableInformation: action.payload.data,
    };
  }

  /// fetch items not recievers by id
  if (action.type === ITEM_NOT_RECEIVER_by_id) {
    return {
      ...state,
      itemsNotReceiversById: action.payload.data,
    };
  }
  /// fetch special
  if (action.type === FETCH_LAST_SERIAL_sp) {
    return {
      ...state,
      fetchlastsp: action.payload.data,
    };
  }
  /// fetch special single
  if (action.type === FETCH_LAST_SERIAL_sp_SINGLE) {
    return {
      ...state,
      fetchSinglelastsp: action.payload.data,
    };
  }
  /// fetch closed cards
  if (action.type === GET_CLOSED_CARDS) {
    return {
      ...state,
      closedCards: action.payload.data ,
    };
  }
  /// get receivers
  if (action.type === FETCH_RECEIVER) {
    return {
      ...state,
      getReceiver: action.payload.data,
    };
  }
  /// transfer item
  if (action.type === TRANSFER_ITEM) {
    return {
      ...state,
      transferItem: action.payload.data,
    };
  }
  /// GET MENUS
  if (action.type === GET_MENUS) {
    return {
      ...state,
      menus: action.payload.response,
      isLoading: false,
    };
  }

  // get districts
  if (action.type === GET_DISTRICTS) {
    return {
      ...state,
      districts: action.payload.data,
    };
  }
  // get hormuud iptvs
  if (action.type === FETCH_HOR_IPT) {
    return {
      ...state,
      horIptvs: action.payload.data,
    };
  }

  /// FETCH ANALOG OPEN CARDS REPORST
  if (action.type === FETCH_OPEN_CARD_REPORT) {
    return {
      ...state,
      openCardsReport: action.payload.data,
    };
  }

  // FETCH REDLINE OPENC CARDS REPORT
  if (action.type === "FETCH_REDLINE_OPEN_CARD_REPORT") {
    return {
      ...state,
      RedlineCardsReport: action.payload.data,
    };
  }
  // FETCH SUMMARY cable REPORT
  if (action.type === FETCH_CABLE_REPORT) {
    return {
      ...state,
      summaryCableReport: action.payload.data,
    };
  }
  // FETCH SUMMARY close card REPORT
  if (action.type === FETCH_CLOSE_CARDS_REPORT) {
    return {
      ...state,
      summaryCloseReport: action.payload.data,
    };
  }
  /// FETCH all OPEN CARDS REPORST
  if (action.type === FETCH_ALL_OPEN_CARD_REPORT) {
    return {
      ...state,
      allopenCardReport: action.payload.data,
    };
  }

  //LOADING

  // ANALOG CARDS
  if (action.type === OPEN_CARD_REG_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === OPEN_CARD_REG_END) {
    return {
      ...state,
      isLoading: false,
    };
  }
  // REDLINE CARDS
  if (action.type === "OPEN_REDLINE_CARD_REG_BEGIN") {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === "OPEN_REDLINE_CARD_REG_END") {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (action.type === LOADING2) {
    return {
      ...state,
      isLoading: true,
    };
  }

  // RESET ITEM TRANSFER
  if (action.type === RESET_ITEM_TRANSFER) {
    return {
      ...state,
      fetchSinglelastsp: "",
    };
  }
  // RESET the cardnumbers
  if (action.type === RESET_THE_CARDNUMBER) {
    return {
      ...state,
      reciveById: "",
    };
  }

  /// reset receiver transfer
  if (action.type === RESET_RECEIVER_TRANSFER) {
    return {
      ...state,
      fetchLastSerial: "",
    };
  }
  /// reset billing receiver
  if (action.type === RESET_BILLING_RECEIVER) {
    return {
      ...state,
      cableInformation: "",
    };
  }
  /// reset billing ITEMS
  if (action.type === RESET_BILLING_ITEM) {
    return {
      ...state,
      itemsNotReceiversById: "",
      getCustomersId: "",
    };
  }
  /// reset ITEM SALES ITEMS
  if (action.type === RESET_ITEM_SALES_ITEM) {
    return {
      ...state,
      fetchSinglelastsp: "",
    };
  }
  /// reset ITEM SALES RECEIVERS
  if (action.type === RESET_ITEM_SALES_RECEIVER) {
    return {
      ...state,
      getReceiver: "",
    };
  }
  /// reset QUANTITY
  if (action.type === RESET_QUANTITY_AVAILABLE) {
    return {
      ...state,
      quantityAvailable: "",
    };
  }
  /// reset menus
  if (action.type === "RESET_THE_MENUS") {
    return {
      ...state,
      singleUserMenu: [],
    };
  }
  /// reset menus
  if (action.type === "RESET_THE_REPORT") {
    return {
      ...state,
      userRoleReport: [],
    };
  }

  /// fetch the customers
  if (action.type === THE_CUSTOMERS_BEGIN) {
    return {
      ...state,
      isNewCableLoading:true,
      
    };
  }
  if (action.type === THE_CUSTOMERS_ERROR) {
    return {
      ...state,
 
      isNewCableLoading:false,
      
    };
  }
  if (action.type === THE_CUSTOMERS_SUCCESS) {
    return {
      ...state,
      the_customers: action.payload.items,
      isNewCableLoading:false
    };
  }
  /// ** iprtv organization
  if (action.type === REGISTER_IPT_ORGANIZATION_BEGIN) {
    return {
      ...state,
      isOrgLoading:true
    };
  }
  if (action.type === REGISTER_IPT_ORGANIZATION__SUCCESS) {
    return {
      ...state,
 
      isOrgLoading:false
    };
  }
  if (action.type === REGISTER_IPT_ORGANIZATION__ERROR) {
    return {
      ...state,
      the_customers: action.payload.items,
      isOrgLoading:false
    };
  }

  if (action.type === GET_SUPERVISORS) {
    return {
      ...state,
      supervisors: action.payload.response,
    };
  }
  /// fetch updated customers
  if (action.type === UPDATED_CUSTOMERS) {
    return {
      ...state,
      updatedCustomers: action.payload.data,
    };
  }
  /// fetch BOTH customers
  if (action.type === "FETCH_BOTH") {
    return {
      ...state,
      bothcustomers: action.payload.data,
    };
  }
  /// fetch iptv customers
  if (action.type === IPTV_CUSTOMER) {
    return {
      ...state,
      iptvCustomer: action.payload.data,
    };
  }
  if (action.type === "FETCH_REDLINE") {
    return {
      ...state,
      RedlineCustomers: action.payload.data,
    };
  }
  /// fetch SPEACILA SUPERVISORS
  if (action.type === GET_SUPERVISORS_SPECIAL) {
    return {
      ...state,
      specialSupervisors: action.payload.response,
    };
  }
  if (action.type === GET_SINGLE_MENU) {
    return {
      ...state,
      singleUserMenu: action.payload.response,
    };
  }
  // fetch sidebar menus
  if (action.type === GET_SIDEBAR_MENU) {
    return {
      ...state,
      sidebarMenus: action.payload.response,
    };
  }
   // FETCH payroll SUMMARY REPORT
   if (action.type === FETCH_PAYROLL_SUMMARY_REPORT) {
    return {
      ...state,
      payrollSummaryReport: action.payload.data,
    };
  }
   // FETCH payroll details REPORT
   if (action.type === FETCH_PAYROLL_DETAILS_REPORT) {
    return {
      ...state,
      payrollDetailsReport: action.payload.data,
    };
  }
   // FETCH ticket transfer REPORT
   if (action.type === FETCH_TICKET_TRANSFER_REPORT) {
    return {
      ...state,
      ticketTransferReport: action.payload.data,
    };
  }
  //iptv customer recharge
   if (action.type === IPTV_CUSTOMER_RECHARGE) {
    return {
      ...state,
      iptvCustomerRechargeData: action.payload.data,
    };
  }
  // ott customer recharge
   if (action.type === OTT_CUSTOMER_RECHARGE) {
    return {
      ...state,
      ottCustomerRecharges: action.payload.data,
    };
  }
  // expired iptvs 
   if (action.type === EXPIRED_IPTVS_REPORT) {
    return {
      ...state,
      expirecdIptvsReport: action.payload.data,
    };
  }
  // EXPIRED NASIYE CUSTOMERS
   if (action.type === "EXPIRED_NASIYE_CUSTOMERS") {
    return {
      ...state,
      expireNasiyeCustomersReport: action.payload.data,
    };
  }
  // acive nasiye customers
   if (action.type === "ACTIVE_NASIYE_CUSTOMERS") {
    return {
      ...state,
      activeNasiyeIptvs: action.payload.data,
    };
  }
  // expired OTT 
   if (action.type === EXPIRED_OTT_REPORT) {
    return {
      ...state,
      activeNasiyeCustomers: action.payload.data,
    };
  }
  // GET MAC ADDRESS AND SERILA NUMBER  
   if (action.type === GET_MAC_ADDRESS_AND_SERIAL_NUMBER) {
    return {
      ...state,
      macAddressAndSerialNumberData: action.payload.data,
    };
  }
  // GET CUSTONERS WITH ITVS
   if (action.type === FETCH_ONLY_CUSTOMERS_WITH_IPTVS) {
    return {
      ...state,
      customerWithIptvs: action.payload.data,
    };
  }

  // TRANSFER IPTV
  if (action.type === TRANSFER_IPTV_BEGIN) {
    return {
      ...state,
     
      isLoading: true,
    };
  }
  
  if (action.type === TRASFER_IPTV_SUCCESS) {
    return {
      ...state,
     
      isLoading: false,
  
    };
  }
  
   // FETCH all years  REPORT
   if (action.type === GET_ALL_YEARS) {
    return {
      ...state,
      allYears: action.payload.data,
    };
  }
   // NEW ANALOG CABLE
   if (action.type === REGISTER_NEW_CABLE_BEGIN) {
    return {
      ...state,
      isNewCableLoading: true,
    };
  }
   if (action.type === REGISTER_NEW_CABLE_SUCCESS) {
    return {
      ...state,
      isNewCableLoading: false,
    };
  }
   if (action.type === REGISTER_NEW_CABLE_ERROR) {
    return {
      ...state,
      isNewCableLoading: false,
    };
  }

  // NEW REDLINE CABLE
  if (action.type === "REGISTER_NEW_REDLINE_ABLE_BEGIN") {
    return {
      ...state,
      isNewCableLoading: true,
    };
  }
   if (action.type === "REGISTER_NEW_REDLINE_CABLE_SUCCESS") {
    return {
      ...state,
      isNewCableLoading: false,
    };
  }
   if (action.type === "REGISTER_NEW_REDLINE_CABLE_ERROR") {
    return {
      ...state,
      isNewCableLoading: false,
    };
  }
  //SMS
   if (action.type === SEND_SMS_BEGIN) {
    return {
      ...state,
      isSendingSMS: true,
    };
  }
   if (action.type === SEND_SMS_SUCCESS) {
    return {
      ...state,
      isSendingSMS: false,
    };
  }
   if (action.type === SEND_SMS_FAIL) {
    return {
      ...state,
      isSendingSMS: false,
    };
  }
   if (action.type === CUST_SMS_BEGIN) {
    return {
      ...state,
      isSendingSMS: true,
    };
  }
   if (action.type === CUST_SMS_SUCCESS) {
    return {
      ...state,
      isSendingSMS: false,
    };
  }
   if (action.type === CUST_SMS_END) {
    return {
      ...state,
      isSendingSMS: false,
    };
  }
   // ITEM SALES
   if (action.type === REGISTER_NEW_ITEM_SALES_BEGIN) {
    return {
      ...state,
      isNewCableLoading: true,
    };
  }
   if (action.type === REGISTER_NEW_ITEM_SALES_SUCCESS) {
    return {
      ...state,
      isNewCableLoading: false,
    };
  }
   if (action.type === REGISTER_NEW_ITEM_SALES_ERROR) {
    return {
      ...state,
      isNewCableLoading: false,
    };
  }
   // ** managle cables
   if (action.type === FETCH_CABLES_BEGIN_BY_DATE) {
    return {
      ...state,
      isNewCableLoading: true,
    };
  }
   if (action.type === FETCH_CABLES_SUCCESS_BY_DATE) {
    return {
      ...state,
      isNewCableLoading: false,
      cablesManage:  action.payload.data,

    };
  }
   if (action.type === FETCH_CABLES_ERROR_BY_DATE) {
    return {
      ...state,
      isNewCableLoading: false,
    };
  }
   // ** Auto charge
   if (action.type === AUTO_CHARGE_BEGIN) {
    return {
      ...state,
      isNewCableLoading: true,
    };
  }
   if (action.type === AUTO_CHARGE_SUCCESS) {
    return {
      ...state,
      isNewCableLoading: false,
      auto: action.payload.res,

    };
  }
   if (action.type === AUTO_CHARGE_ERROR) {
    return {
      ...state,
      isNewCableLoading: false,
    };
  }

  //*** EXPIRED SMS */
  if (action.type === GET_ALL_ANALOG_EXPIRED_BEGIN) {
    return {
      ...state,
      isNewCableLoading: true,
    };
  }

  if (action.type === GET_ALL_ANALOG_EXPIRED_SUCCESS) {
    return {
      ...state,
      isNewCableLoading: false,
      analogAllExpired: action.payload?.data,

    };
  }
  if (action.type === GET_ALL_ANALOG_THREE_EXPIRED_BEGIN) {
    return {
      ...state,
      isNewCableLoading: true,
    };
  }
  if (action.type === GET_USER_REPORT_ROLE) {
    return {
      ...state,
      userRoleReport: action.payload?.response,
    };
  }

  //*iotv  expired
  if (action.type === GET_ALL_ANALOG_REMIAINGG_EXPIRED_SUCCESS) {
    return {
      ...state,
      isNewCableLoading: false,
      analogThreeRemaingDays: action.payload?.data,

    };
  }
  if (action.type === GET_ALL_IPTV_THREE_EXPIRED_BEGIN) {
    return {
      ...state,
      isNewCableLoading: true,
    };
  }

  if (action.type === GET_ALL_IPTV_REMIAINGG_EXPIRED_SUCCESS) {
    return {
      ...state,
      isNewCableLoading: false,
      iptvAllExpired: action.payload?.data,

    };
  }
  if (action.type === GET_ALL_IPTV_THEE_EXPIRED_BEGIN) {
    return {
      ...state,
      isNewCableLoading: true,
    };
  }

  if (action.type === GET_ALL_IPTV_THEEE_EXPIRED_SUCCESS) {
    return {
      ...state,
      isNewCableLoading: false,
      iptv3DaysExpired: action.payload?.data,

    };
  }
  if (action.type === "SINGLE_REPORT_ROLE") {
    return {
      ...state,
     
      repRoles: action.payload?.data,

    };
  }

  if (action.type === "days3_BEGIN") {
    return {
      ...state,
      isSending: true,
    };
  }
  if (action.type === "days3_SUCCESS") {
    return {
      ...state,
      isSending: false,
    };
  }

  if (action.type === "SMS_IPTV_BEGIN") {
    return {
      ...state,
      isSending: true,
    };
  }
  if (action.type === "SMS_IPTV_END") {
    return {
      ...state,
      isSending: false,
    };
  }
  if (action.type === "SMS_IPTV_3DAYS_BEGIN") {
    return {
      ...state,
      isSending: true,
    };
  }
  if (action.type === "days3_REMAINIG_SUCCESS") {
    return {
      ...state,
      isSending: false,
    };
  }
  if (action.type === "FETCH_REVANUE_REPORT_BEGIN") {
    return {
      ...state,
      isLoading: true,
    };
  }
//enroll employee
  if (action.type === SEND_FINGERPRINT_BEGIN) {
    return {
      ...state,
      isEnrolling: true,
    };
  }
   if (action.type === SEND_FINGERPRINT_SUCCESS) {
    return {
      ...state,
      isEnrolling: false,
    };
  }
   if (action.type === SEND_FINGERPRINT_FAIL) {
    return {
      ...state,
      isEnrolling: false,
    };
  }
// device regsistration
  if (action.type === DEVICES_REG_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  // fetch devices
  if (action.type === FETCH_DEVICES_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      devices: action.payload.data,
    };
  }


  // attendance updates
  if (action.type === "FETCH_ATTENDANCE_BEGIN") {
    return {
      ...state,
      isLoading: true
    };
  }
  if (action.type === "FETCH_ATTENDANCE_END") {
    return {
      ...state,
      isLoading: false
    };
  }
  if (action.type === FETCH_ATTENDANCE_UPDATES) {
    return {
      ...state,
      isLoading: false,
      attendanceUpdates: action.payload?.data,
    };
  }
// available cards 
  if (action.type === FETCH_AVAILABLE_CARDS) {
    return {
      ...state,
      isLoading: false,
      availableCards: action.payload?.data,
    };
  }

  // send customers sms notifications
     
   if (action.type === SEND_SMS_NOTIFICATION) {
    return {
      ...state,
      customersNotification: action.payload.data,
    };
  }
 // FETCH RESOLVED TICKETS REPORT 
 if (action.type === FETCH_RESOLVED_TICKETS) {
  return {
    ...state,
    resolvedTickets: action.payload.data,
  };
}

// fetch sms messages
if (action.type === FETCH_SMS_MESSAGES) {
  return {
    ...state,
    smsMessages: action.payload.data,
  };
}

// fetch sold receivers
if (action.type === FETCH_SOLD_CARDS) {
  return {
    ...state,
    soldReceivers: action.payload.data,
  };
}

if (action.type === CANCEL_CARD_BEGIN) {
  return {
    ...state,
    isLoading: true,
  };
}
if (action.type === CANCEL_CARD_END) {
  return {
    ...state,
    isLoading: false,
  };
}
// fetch completed tickets
if (action.type === GET_TICKETS_COMPLETED_SUCCESS) {
  return {
    ...state,
    completedTickets: action.payload.data,
  };
}
// fetch EXPIRED CUSTOMER CARDS WITH ANALOG
if (action.type === FETCH_EXPIRED_CARDS) {
  return {
    ...state,
    allExpredCustomerCards: action.payload.data,
  };
}
// daily attendance report
if (action.type === "FETCH_DAILY_ATTENDANCE_BEGIN") {
  return {
    ...state,
    isLoading:true,
  };
}
if (action.type === "FETCH_DAILY_ATTENDANCE") {
  return {
    ...state,
    isLoading:false,
    dailyAttendanceReport:action.payload.data,
  };
}

// detailed attendance data
if (action.type === "FETCH_ATTENDANCE_DATA_BEGIN") {
  return {
    ...state,
    isLoading:true,
  };
}
if (action.type === "FETCH_ATTENDANCE_DATA") {
  return {
    ...state,
    isLoading: false,
    attendanceData: action.payload.data,
  };
}
// get monthly attendance report
if (action.type === "FETCH_MONTHLY_ATTENDANCE_DATA_BEGIN") {
  return {
    ...state,
    isLoading:true,
  };
}
if (action.type === "FETCH_MONTHLY_ATTENDANCE_DATA") {
  return {
    ...state,
    isLoading:false,
    monthylyAttendanceReport:action.payload.data,
  };
}
if (action.type === GET_ALL_IPTV_CUST) {
  return {
    ...state,
    
    allIPtvCustomers:action.payload.data,
  };
}

  if (action.type === "FETCH_REVANUE_REPORT_SUCCESS") {
    return {
      ...state,
      isLoading: false,
      paymentReport: action.payload?.data,

    };
  }
  if (action.type === "EMP_DEPARTMENT_SUCCESS") {
    return {
      ...state,
      isLoading: false,
      EmployeesByDepartments: action.payload?.data,

    };
  }
  if (action.type === "IPTV_ORGANIZATION_REPORT") {
    return {
      ...state,
      isLoading: false,
      iptvOrganizationsReport: action.payload?.data,

    };
  }
  if (action.type === "FECH_LEAVE_CATEGORIES") {
    return {
      ...state,
      isLoading: false,
      LeaveCategories: action.payload?.data,

    };
  }
  if (action.type === "FETCH_EMPLOYEE_LEAVES") {
    return {
      ...state,
      isLoading: false,
      EmployeeLeaves: action.payload?.data,

    };
  }

  // fetch multi shifts
  if (action.type === "FECH_MULTI_SHIFTS") {
    return {
      ...state,
      isLoading: false,
      MultiShifts: action.payload?.data,

    };
  }
  if (action.type === "MULTI_SHIFT_ERROR") {
    return {
      ...state,
      isLoading:false,
    };
  }
  if (action.type === "FECH_EMP_MULTI_SHIFTS") {
    return {
      ...state,
      isLoading: false,
      EmployeesWithMultipleShifts: action.payload?.data,

    };
  }
  return state;
};

export default reducer;
