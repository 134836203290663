/* eslint-disable */ 
import React, { useState, useEffect, useContext, useMemo } from "react";
import Select from "react-select";
import { Grid, _ } from "gridjs-react";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
// import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";

// Import Images
import avatar1 from "../../../src/assets/images/users/avatar-1.jpg";
import avatar2 from "../../../src/assets/images/users/avatar-2.jpg";
import avatar3 from "../../../src/assets/images/users/avatar-3.jpg";
import avatar4 from "../../../src/assets/images/users/avatar-4.jpg";
import avatar5 from "../../../src/assets/images/users/avatar-5.jpg";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { LoginContex } from "../../context/loginContext/LoginContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableContainer from "../../Components/Common/TableContainer";
import { TextField } from "@mui/material";

const OpenClosedCard = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [modal_list1, setmodal_list1] = useState(false);
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [newCardNumber, setNewCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardPackage, setCardPackage] = useState("");
  const [customer, setCustomer] = useState();
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [openDate, setOpenDate] = useState("");
  const [closeDate, setCloseDate] = useState("");
  const [usingDays, setUsingDays] = useState("");
  const [amount, setAmount] = useState(8);
  const [Discription, setdescription] = useState("");
  const { openAnalogCardRegister, customers } = useContext(LoginContex);
  const [type, setTyp] = useState("text");
  const [customerInfo, setCustomerInfo] = useState();
  const [customerId, setCustomerId] = useState();
  const [Custom, setCustom] = useState("text");
  const [channelId, setChanell] = useState();
  const [cycle, setCycle] = useState("");
  const [numberOfDays, setNumberOfDays] = useState();
  const [numberOfMonths, setNumberOfMonths] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [CustometINFO, setCustometINFO] = useState();
  const [custPhone, setCustPhone] = useState();
  const [billAmount, setBillAmount] = useState();
  const [selectedCard, setSelectedCard] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  const tog_list1 = () => {
    setmodal_list1(!modal_list1);
  };

  const [modal_delete, setmodal_delete] = useState(false);

  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  document.title = "Transfer Card ";

  const {
    getAnalogCustomerCardList,
    cardList,

    isLoading,User,isNewCableLoading, getAnalogCardNumberBySearch, AnalogReciverBySearch, TransferAnalogCardNumber,
    updatedCustomers,
    fetchAutoCustomers,
  } = useContext(LoginContex);

  useEffect(() => {
    getAnalogCustomerCardList();
  }, []);


  let getCustcardNumber = (id) => {
     setCustomerInfo(id);
     setCardNumber(id.cardNumber);
  };


  let userId = User?.data?.user?._id || User?.user?._id;

  const showToastMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const handlerSubmitOpen = (e) => {
    //console.log(e);
    e.preventDefault();
    if (
      !customerInfo?.cardNumber 
      

    ) {
      showToastMessage("please provide required values");
      return;
    }

    const data = {
      oldCardNumber: customerInfo?.cardNumber,
      customerId: customerInfo?.value,
      newCardNumber: newCardNumber,
      userId: userId,
      type: 'Transfer'
    }

    console.log("submitted data " ,data)
    TransferAnalogCardNumber(data)
   
    e.target.reset();
 
  };
  const GiveNewCard= (e) => {
    //console.log(e);
    e.preventDefault();
    if (
      !newCardNumber
      

    ) {
      showToastMessage("please provide required values");
      return;
    }

    // console.log(CustometINFO)

    const data = {
      customerId: CustometINFO?._id,
      newCardNumber: newCardNumber,
      oldCardNumber:null,
      userId: userId,
      type: 'New'
    }

    // console.log("submitted data " ,data)

    TransferAnalogCardNumber(data)
    e.target.reset();
    setCustometINFO("")
    setNewCardNumber("")
    setSelectedOption("")
    setSelectedCard(null);
    setNewCardNumber("");
  }
 

  const getOptionSelected = (option, value) => {
    if (option && value) {
      return option.id === value.id;
    }
    return false;
  };

  const getSelectedCard = (option, value) => {
    if (option && value) {
      return option.id === value.id;
    }
    return false;
  };



  const CustomersArr = [];

  for (let i = 0; i < customers.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: customers[i]._id,
      label: ` ${customers[i].customerName}`,
    };

    CustomersArr.push(dropdownList);
  }
 

  const customerCards = [];

  for (let i = 0; i < cardList.length; i++) {
    // //console.log(customers[i]._id);

    var dropdownList = {
      value: cardList?.[i]?.dataCustomers?._id,
      cardNumber: cardList?.[i]?.dataCustomers?.cardNumber,
      customerPhone: cardList?.[i]?.dataCustomers?.customerPhone,
      receiverType: cardList?.[i]?.datareceivers?.itemType,
      label: `${cardList?.[i]?.dataCustomers?.customerName} -- ${cardList?.[i]?.dataCustomers?.cardNumber}`,
    };

    customerCards.push(dropdownList);
  }

  const makeItArray = [];

  for (let i = 0; i < AnalogReciverBySearch.length; i++) {
    // ////console.log"from: " + formattedDate);
    let singleArr = {
      cardNum: AnalogReciverBySearch[i]?.cardNumber,
    };

    makeItArray.push(singleArr);
  }

  const onInputChange = (event, value, reason) => {
    //console.logvalue);
    setNewCardNumber(value);

    getAnalogCardNumberBySearch(value);

    //console.logreciveById);
  };
  const onInputChangeNewCardNumber = (event, value, reason) => {
    //console.logvalue);
    setNewCardNumber(value);

    getAnalogCardNumberBySearch(value);

    //console.logreciveById);
  };
  const onInputChange2 = (event, value, reason) => {
    
    fetchAutoCustomers(value);

    const matchingOption = makeItArray2.find(
      (option) =>
        option.customers.toLowerCase() ==
        value.substring(0, value.indexOf("-")).toLowerCase()
    );
    // console.log(matchingOption)
    setCustometINFO(matchingOption);

    setSelectedOption(matchingOption);

    //console.logreciveById);
  };
  const makeItArray2 = [];
  //console.logupdatedCustomers);

  for (let i = 0; i < updatedCustomers?.length; i++) {
    // ////console.log"from: " + formattedDate);
    let singleArr = {
      customers: updatedCustomers[i]?.customerName,
      customerPhone: updatedCustomers[i]?.customerPhone,
      _id: updatedCustomers[i]?._id,
      customerAddress: updatedCustomers[i]?.customerAddress,
      districtName: updatedCustomers[i]?.districtName,
    };

    makeItArray2.push(singleArr);
  }
 



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Transfer Card " pageTitle="Transfer Card " />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div>
                        <ToastContainer />
                        <div>
                          <h4 className="card-title mb-0">Transfer Card  and Give New Card</h4>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div>
                          <Button
                            color="success"
                            className="add-btn me-1"
                            onClick={tog_list1}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Give new Card
                          </Button>
                          <Button
                            color="primary"
                            className="add-btn me-1"
                            onClick={tog_list}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Transfer Card
                          </Button>
                       
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                {/* {load()} */}
               
              </Card>
            </Col>
          </Row>

          {/* end of container */}
        </Container>
      </div>

      {/* Add Modal */}
      {/* Open Closed Card Distribution From */}

      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Transfer Analog Card Number From Customer</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={handlerSubmitOpen}>
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>
      
              <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Customer</label>
                  <Select
                    // className="bg-white"
                    options={customerCards}

                    // onChange={(e) => getCustcardNumber(e.target)}
                    onChange={(choice) =>
                      // getCustPhone(e.target.value, e.target)
                      getCustcardNumber(choice)
                    }
                    value={customerId}
                    required
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Card Number</label>
                  <input
                    type="text"
                    id="card_number-field"
                    className="form-control"
                    placeholder="Enter Card Number"
                    required
                    disabled
                    value={cardNumber}
                    // value={customerInfo?.cardNumber}
                  />
                </div>
              </Col>
           
     
            </Row>
            <Row>
         
                <Col md={6}>
                <div className="mb-3">
                  <label className="form-label">Phone</label>
                  <input
                    type="text"
                    id="card_number-field"
                    className="form-control"
                    placeholder="Enter Phone Number"
                    required
                    disabled
                    value={customerInfo?.customerPhone}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mt-4">
                
                  <Autocomplete
                                     disabled={isNewCableLoading}
                                      disablePortal
                                      id="combo-box-demo"
                                      size="small"
                                      options={makeItArray}
                                      getOptionLabel={(option) =>
                                        option?.cardNum?.toString()
                                      }
                                      onInputChange={onInputChange}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="New Card Number"
                                        />
                                      )}
                                    />
                </div>
              </Col>
              <Col md={4}>
              
              </Col>
       
           
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>

              <Button type="submit" className="btn btn-success">
                Transfer
              </Button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <Modal
        isOpen={modal_list1}
        toggle={() => {
          tog_list1();
        }}
        centered
        size="lg"
        backdrop={"static"}
      >
        <div className="bg-light p-3 modal-header">
          <h5 className="modal-title"> Give New Card Number</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_list1(false);
            }}
            aria-label="Close"
          ></button>
        </div>

        <form onSubmit={GiveNewCard}>
          <ModalBody>
         
            <Row>
        
            <Col md={6}>
            <div className="mb-3">
          
                      
                            <Label className="form-label">
                              Customer <span className="text-danger">*</span>
                            </Label>
                           
                                <Autocomplete
                                 disabled={isNewCableLoading}
                                  disablePortal
                                  id="combo-box-demo"
                                  size="small"
                                  options={makeItArray2}
                                  getOptionSelected={getOptionSelected}
                                  getOptionLabel={(option) => {
                                    return `${option.customers?.toString()}-${option.customerPhone?.toString()}`;
                                  }}
                                  value={selectedOption}
                                  onInputChange={onInputChange2}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Search Customer..."
                                    />
                                  )}
                                />
                              </div>
                           
                          
                        </Col>

                        <Col md={3}>
                        <div className="mb-3">
                          <label className="form-label">Phone Number</label>{" "}
                         
                          <input
                            type="text"
                            id="referenceno-field"
                            className="form-control"
                            placeholder="Explicit Phone Number"
                            onChange={(e) => setCustPhone(e.target.value)}
                            value={selectedOption?.customerPhone}
                            // defaultValue={getCustomersId.customerPhone}
                            disabled

                          />
                          </div>
                        </Col>
              <Col md={3}>
                <div className="mb-3">
                <label className="form-label">Card Number</label>{" "}
                          <span className="text-danger">*</span>
                  <Autocomplete
                                     disabled={isNewCableLoading}
                                      disablePortal
                                      id="combo-box-demo"
                                      size="small"
                                      options={makeItArray}
                                      getOptionLabel={(option) =>
                                        option?.cardNum?.toString()
                                      }
                                      value={selectedCard}
                                      onChange={(event, newValue) => setSelectedCard(newValue)}
                                      inputValue={newCardNumber}
        
                                      onInputChange={onInputChangeNewCardNumber}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="New Card Number"
                                        />
                                      )}
                                    />
                </div>
              </Col>
              </Row>

          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list1(false)}
              >
                Close
              </button>

              <Button type="submit" className="btn btn-success">
                Submit
              </Button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    
    </React.Fragment>
  );
};

export default OpenClosedCard;
